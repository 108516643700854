import { Component, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'

@Component
export default class DeviceTelemetryDialog extends Vue {
  public isOpen = false
  public title = ''
  public telemetryData: TelemetryData[] = []

  public open(telemetryData: TelemetryData[], deviceName: string, timestamp: number): void {
    this.title = `${deviceName} ${this.$t('deviceTelemetryPage.deviceTelemetryDialog.dialogTitlePartText')} ${dayjs(timestamp * 1000).format('MM.DD.YYYY HH:mm:ss')}`
    this.telemetryData = telemetryData
    this.isOpen = true
  }

  public close(): void {
    this.isOpen = false

    this.telemetryData = []
  }
}

export interface TelemetryData {
  name: string
  value: string
}
