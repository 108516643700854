import { Component, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import { FlespiService } from '@/App/Services/Flespi.service'
import TableHeader from '@/App/Components/TableHeader/Table-Header.vue'
import IntervalPicker from '@/App/Components/Pickers/IntervalPicker/Interval-Picker.vue'
import DeviceTelemetryDialog from './DeviceTelemetryDialog/Device-Telemetry-Dialog.vue'
import { TelemetryData } from './DeviceTelemetryDialog/DeviceTelemetryDialog'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { DateTimeInterval } from '@/App/Components/Pickers'

@Component({
  components: { TableHeader, DeviceTelemetryDialog, IntervalPicker }
})
export default class DeviceTelemetryPage extends Vue {
  $refs: {
    deviceTelemetryDialog: HTMLFormElement,
    intervalPicker: HTMLFormElement
  }

  public headers: VuetifyTableHeader[] = [
    { text: this.$t('deviceTelemetryPage.tableColMessageTimeText').toString(), align: 'left', sortable: true, value: 'timestamp' },
    { text: this.$t('deviceTelemetryPage.tableColIdentText').toString(), align: 'left', sortable: true, value: 'ident' },
    { text: this.$t('deviceTelemetryPage.tableColActionText').toString(), value: 'left', align: 'center', sortable: false }
  ]
  public tableData: TableData[] = []
  public telemetryData: TelemetryItem[] = []
  public isLoading = false
  public search = ''
  public title = ''

  public mounted(): void {
    this.title = `${this.$route.query.deviceName} ${this.$t('deviceTelemetryPage.tablePartTitle')}`
    this.fetchTelemetryData(dayjs().startOf('day').unix(), dayjs().endOf('day').unix())
  }

  private async fetchTelemetryData(from: number, to: number): Promise<void> {
    try {
      this.isLoading = true

      this.telemetryData = await FlespiService.fetchDeviceTelemetryMessages({
        deviceId: +this.$route.params.id,
        from,
        to
      })

      this.tableData = this.telemetryData.map(td => ({
        ident: td.ident.toString(),
        timestamp: +td.timestamp
      }))

    } catch {} finally {
      this.isLoading = false
    }
  }

  public telemetrySearch(text: string): void {
    this.search = text
  }

  public openTelemetryDialog(timestamp: number): void {
    const telemetryData: TelemetryData[] = []
    const item = this.telemetryData.find(td => td.timestamp === timestamp)!
    Object.keys(item).forEach(key => telemetryData.push({ name: key, value: item[key].toString() }))

    this.$refs.deviceTelemetryDialog.open(telemetryData, this.$route.query.deviceName, timestamp)
  }

  public async selectTimeInterval(): Promise<void> {
    const date = await this.$refs.intervalPicker.open(true) as DateTimeInterval | false

    if (date) {
      this.fetchTelemetryData(date.from, date.to)
    }
  }
}

interface TableData {
  ident: string
  timestamp: number
}

interface TelemetryItem {
  [key: string]: number | string
}
